body {
  font: 35px "Century Gothic", Futura, sans-serif;
  margin: 0px;
  color: #003366;
  text-align: center;
  background: linear-gradient(to bottom, #f0f0f0, #b3b3b3);
}

ol, ul {
  padding-left: 0px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.square {
  background: #003366;
  border: 1px solid #999;
  float: left;
  font-size: 95px;
  font-weight: bold;
  color: white;
  line-height: 100px;
  height: 100px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  position: static;
  text-align: center;
  width: 100px;
  transition: background 0.3s, -webkit-transform 0.3s;
  transition: background 0.3s, transform 0.3s;
  transition: background 0.3s, transform 0.3s, -webkit-transform 0.3s;
}

.square:focus {
  outline: none;
}

.square:hover {
  background: #005599;
  cursor: pointer;
}

.highlight {
  background: #5cb85c;
  color: #fff;
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

.kbd-navigation .square:focus {
  background: #003366;
}

.game-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.game-info {
  margin-right: 40px;
  flex: 1 1;
  background-color: #color1;
}

.game {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 0;
  background-color: #color2;
}

.move-btn {
  background: #f0f0f0;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  margin: 2px;
  transition: background-color 0.3s;
}

.move-btn:hover {
  background-color: #ddd;
}

.move-btn.active {
  font-weight: bold;
  background-color: #ccc;
}

.links {
  padding-top: 40px;
}

.leaderboard {
  margin-left: 40px;
  background-color: #color3;
}

.leaderboard table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard th,
.leaderboard td {
  border: 1px solid #999;
  padding: 0.5rem;
  text-align: center;
  font-size: 20px;
}

.leaderboard h2 {
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 20px;
}

.footer {
  width: 100%;
  padding: 10px 0;
  text-align: center;
  background-color: #f0f0f0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}
